import { createRouter, createWebHistory } from "@ionic/vue-router";
import Home from "@/views/Home/Home.vue";
import AuthFinalize from "../views/AuthFinalize/AuthFinalize.vue";
import ProfileInfo from "../views/ProfileInfo/ProfileInfo.vue";
import LearnHome from "../views/LearnHome/LearnHome.vue";
import ResourceDetail from "../views/ResourceDetail/ResourceDetail.vue";
import ContentPackDetail from "../views/ContentPackDetail/ContentPackDetail.vue";
import CourseHome from "../views/CourseHome/CourseHome.vue";
import CourseDetail from "../views/CourseDetail/CourseDetail.vue";
import CollectionHome from "../views/CollectionHome/CollectionHome.vue";
import CollectionDetail from "../views/CollectionDetail/CollectionDetail.vue";
import TermsAndConditions from "@/views/TermsAndConditions/TermsAndConditions.vue";
import Tabs from "@/views/Tabs/Tabs.vue";
import ErrorPage from "@/views/ErrorPage/ErrorPage.vue";
import http from "@/http-common";
import Version from "@/views/Version/Version.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import analytics from "@/analytics";
import ToolsHome from "@/views/ToolsHome/ToolsHome.vue";
import AssessmentDetail from "@/views/AssessmentDetail/AssessmentDetail.vue";
import AssessmentQuestion from "@/views/AssessmentQuestion/AssessmentQuestion.vue";
import ResumeOverview from "@/views/ResumeOverview/ResumeOverview.vue";
import ResumeContactDetails from "@/views/ResumeContactDetails/ResumeContactDetails.vue";
import ResumeWorkExperiences from "@/views/ResumeWorkExperiences/ResumeWorkExperiences.vue";
import ResumeWorkExperienceDetail from "@/views/ResumeWorkExperienceDetail/ResumeWorkExperienceDetail.vue";
import ResumeQualifications from "@/views/ResumeQualifications/ResumeQualifications.vue";
import ResumeQualificationDetail from "@/views/ResumeQualificationDetail/ResumeQualificationDetail.vue";
import ResumeLicences from "@/views/ResumeLicences/ResumeLicences.vue";
import ResumeLicenceDetail from "@/views/ResumeLicenceDetail/ResumeLicenceDetail.vue";
import ResumePersonalStatement from "@/views/ResumePersonalStatement/ResumePersonalStatement.vue";
import OnboardingSlides from "@/views/OnboardingSlides/OnboardingSlides.vue";

import { useAuthStore, useMsalStore, useFeatureFlagStore } from "@/store";

const routes = [
  {
    path: "/",
    name: "welcome",
    component: Home,
    beforeEnter: () => {
      const auth = useAuthStore();
      if (auth.userInfo) {
        return { name: "Home" };
      }
    },
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: OnboardingSlides,
    meta: {
      mainContentId: "onboarding-slides-main-content",
      requireFeatureFlag: "release-page-onboarding",
      defaultFlag: true,
    },
  },
  {
    path: "/tabs/",
    name: "tabs",
    component: Tabs,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "default",
        redirect: "/tabs/home",
      },
      {
        path: "home",
        name: "Home",
        component: Dashboard,
        meta: {
          title: "pageTitles.home",
          mainContentId: "dashboard-main-content",
        },
      },
      {
        path: "learn-home",
        name: "Learn and Grow",
        component: LearnHome,
        meta: {
          title: "pageTitles.learn",
          requireFeatureFlag: "release-page-learn-and-grow-home",
          defaultFlag: true, // TODO: remove default fallback once aproved by IT
          mainContentId: "learn-home-main-content",
        },
      },
      {
        path: "learn-home/learn/:id",
        name: "ResourceDetail",
        component: ResourceDetail,
        props: true,
        meta: {
          title: "pageTitles.learn",
          mainContentId: "resource-detail-main-content",
        },
      },
      {
        path: "learn-home/course",
        name: "Course",
        component: CourseHome,
        meta: {
          title: "pageTitles.learn",
          mainContentId: "course-home-main-content",
        },
      },
      {
        path: "learn-home/course/:id",
        name: "CourseDetail",
        component: CourseDetail,
        meta: {
          title: "pageTitles.learn",
          mainContentId: "course-detail-main-content",
        },
      },
      {
        path: "learn-home/collection",
        name: "Collection",
        component: CollectionHome,
        meta: {
          title: "pageTitles.learn",
          mainContentId: "collection-home-main-content",
        },
      },
      {
        path: "learn-home/collection/:id",
        name: "CollectionDetail",
        component: CollectionDetail,
        meta: {
          title: "Learn and Grow",
          mainContentId: "collection-detail-main-content",
        },
      },
      {
        path: "learn-home/content-pack/:id",
        name: "Content Pack Detail",
        component: ContentPackDetail,
        meta: {
          title: "pageTitles.learn",
          mainContentId: "content-pack-detail-main-content",
        },
      },
      {
        path: "jobs",
        name: "Jobs",
        component: Dashboard,
        meta: {
          title: "pageTitles.jobs",
          requireFeatureFlag: "release-page-jobs-home",
        },
      },
      {
        path: "tools",
        name: "Tools",
        component: ToolsHome,
        meta: {
          title: "pageTitles.tools",
          requireFeatureFlag: "release-page-tools-home",
          defaultFlag: true,
          mainContentId: "tools-home-main-content",
        },
      },
      {
        path: "tools/quiz/:id",
        name: "AssessmentDetail",
        component: AssessmentDetail,
        props: true,
        meta: {
          title: "Quiz",
          requireFeatureFlag: "release-component-assessments",
          mainContentId: "assessment-detail-main-content",
        },
      },
      {
        path: "tools/quiz/:id/questions",
        name: "AssessmentQuestion",
        component: AssessmentQuestion,
        meta: {
          title: "Quiz",
          requireFeatureFlag: "release-component-assessments",
          mainContentId: "assessment-question-main-content",
        },
      },
      {
        path: "saved",
        name: "Saved",
        component: Dashboard,
        meta: {
          title: "Saved",
          mainContentId: "dashboard-main-content",
        },
      },
      {
        path: "tools/resume",
        name: "ResumeOverview",
        component: ResumeOverview,
        meta: {
          title: "pageTitles.tools",
          requireFeatureFlag: "release-component-resume",
          mainContentId: "resume-overview-main-content",
        },
      },
      {
        path: "tools/resume/my-details",
        name: "ResumeContactDetails",
        component: ResumeContactDetails,
        meta: {
          title: "ResumeContactDetails",
          requireFeatureFlag: "release-component-resume",
          mainContentId: "resume-contact-details-main-content",
        },
      },
      {
        path: "tools/resume/my-experience",
        name: "ResumeWorkExperiences",
        component: ResumeWorkExperiences,
        meta: {
          title: "ResumeWorkExperiences",
          requireFeatureFlag: "release-component-resume",
          mainContentId: "resume-work-experiences-main-content",
        },
      },
      {
        path: "tools/resume/my-experience/:id",
        name: "ResumeWorkExperienceDetail",
        component: ResumeWorkExperienceDetail,
        props: true,
        meta: {
          title: "ResumeWorkExperienceDetail",
          requireFeatureFlag: "release-component-resume",
          mainContentId: "resume-work-experience-detail-main-content",
        },
      },
      {
        path: "tools/resume/my-qualifications",
        name: "ResumeQualifications",
        component: ResumeQualifications,
        meta: {
          title: "ResumeQualifications",
          requireFeatureFlag: "release-component-resume",
          mainContentId: "resume-qualifications-main-content",
        },
      },
      {
        path: "tools/resume/my-qualifications/:id",
        name: "ResumeQualificationDetail",
        component: ResumeQualificationDetail,
        props: true,
        meta: {
          title: "ResumeQualificationDetail",
          requireFeatureFlag: "release-component-resume",
          mainContentId: "resume-qualification-detail-main-content",
        },
      },
      {
        path: "tools/resume/my-licences",
        name: "ResumeLicences",
        component: ResumeLicences,
        meta: {
          title: "ResumeLicences",
          requireFeatureFlag: "release-component-resume",
          mainContentId: "resume-licences-main-content",
        },
      },
      {
        path: "tools/resume/my-licences/:id",
        name: "ResumeLicenceDetail",
        component: ResumeLicenceDetail,
        props: true,
        meta: {
          title: "ResumeLicenceDetail",
          requireFeatureFlag: "release-component-resume",
          mainContentId: "resume-licence-detail-main-content",
        },
      },
      {
        path: "tools/resume/personal-statement",
        name: "ResumePersonalStatement",
        component: ResumePersonalStatement,
        meta: {
          title: "ResumePersonalStatement",
          requireFeatureFlag: "release-component-resume",
          mainContentId: "resume-personal-statement-main-content",
        },
      },
      {
        path: "plan",
        name: "Plan",
        component: Dashboard,
        meta: {
          title: "Plan",
          mainContentId: "dashboard-main-content",
        },
      },

      {
        path: "info",
        name: "Information",
        component: ProfileInfo,
        meta: {
          title: "pageTitles.info",
          mainContentId: "profile-info-main-content",
        },
      },
      {
        path: "error",
        name: "Error",
        component: ErrorPage,
        props: { errorType: "pageNotFound" },
        meta: {
          mainContentId: "error-page-main-content",
        },
      },
      {
        path: "version",
        name: "Version",
        component: Version,
        meta: {
          title: "Version",
        },
      },
    ],
  },
  {
    path: "/authfinalize",
    name: "Authfinalize",
    component: AuthFinalize,
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsAndConditions,
    meta: {
      requiresAuth: false,
      title: "pageTitles.t&c",
    },
  },
  {
    path: "/version",
    redirect: "/tabs/version",
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/tabs/error",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const { requiresAuth, requireFeatureFlag, defaultFlag } = to.meta;
  const auth = useAuthStore();
  const featureFlagStore = useFeatureFlagStore();
  // Reset tabindex order after each route navigation to display skip to link on first tab
  document.body.setAttribute("tabindex", "-1");
  document.body.focus();
  document.body.removeAttribute("tabindex");

  // redirect to login page if not logged in and trying to access a restricted page

  const msalStore = useMsalStore();

  // Ensure msalStore is initialized as required for Auth
  if (!msalStore.isReady) {
    await msalStore.initialize();
  }

  // Save the url to redirect to the requested page after successful login
  if (to.fullPath !== "/" && !to.fullPath.startsWith("/authfinalize")) {
    window.sessionStorage.returnUrl = to.fullPath;
  }
  if (requiresAuth && !auth.userInfo) {
    console.log(
      "Router.beforeEach: No authStore.userInfo for required page ",
      to.path
    );
    return { name: "welcome" };
  }
  if (requiresAuth) {
    http
      .post(`/reporting/page-visits`, {
        pageUrl: to.fullPath,
        pageName: to.name,
      })
      .catch((e) => {
        console.error(e);
      });
  }

  // Redirect to Welcome page if feature flag on route is turned off.
  if (!featureFlagStore.isInitialized) {
    await featureFlagStore.initialize();
  }
  if (
    requireFeatureFlag &&
    featureFlagStore.isFlagEnabled(requireFeatureFlag, defaultFlag) === false
  ) {
    return { name: "welcome" };
  }
});

router.afterEach(() => {
  analytics.page();
});

export default router;
