<template>
  <ion-page>
    <div class="content">
      <div class="feature-image" :style="featureImageStyle"></div>
      <div
        class="sign-in-container"
        :style="{ backgroundImage: `url(${signInBackgroundImage})` }"
      >
        <ion-header class="ion-no-border">
          <ion-toolbar>
            <div
              class="locale-selector"
              v-if="localeStore.getShowLocaleSwitcher"
            >
              <LocaleSelector
                :selectedLocaleCode="localeStore.currentLocale"
                :allowedLocaleCodes="localeStore.getAllowedLocaleCodes"
              ></LocaleSelector>
            </div>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <div class="sign-in-form">
            <div :class="logoWrapperClass">
              <img
                tabindex="0"
                class="sign-in-logo"
                role="presentation"
                :src="logo"
                :alt="$t('verificationPage.regionLogo')"
              />
            </div>
            <div :class="innerWrapperClass">
              <h1 aria-label="title" class="title">
                {{ $t("signInHome.title") }}
              </h1>
              <h2 aria-label="subtitle" class="subtitle">
                {{ $t("signInHome.label") }}
              </h2>

              <ion-button
                @click="signInAZ"
                class="sign-in-button"
                expand="block"
                color="primary"
                size="default"
              >
                {{ $t("signInHome.signInCta") }}
              </ion-button>
              <ion-button
                @click="signInAZMfa"
                class="sign-in-button"
                expand="block"
                color="primary"
                size="default"
                style="margin-top: 10px"
              >
                {{ $t("signInHome.signInCtaMfa") }}
              </ion-button>
              <h3 class="built-by">{{ $t("signInHome.footerCredits") }}</h3>
            </div>
          </div>
        </ion-content>
        <global-footer />
      </div>
    </div>
  </ion-page>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButton,
} from "@ionic/vue";
import EnvironmentConfig from "@/EnvironmentConfig";
import { useAuthStore, useLocaleStore, useMsalStore } from "@/store";
import LocaleSelector from "@/components/Common/LocaleSelector/LocaleSelector.vue";
import GlobalFooter from "@/components/Common/GlobalFooter/GlobalFooter.vue";

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonContent,
    IonButton,
    IonPage,
    LocaleSelector,
    GlobalFooter,
  },
  setup() {
    const localeStore = useLocaleStore();
    const authStore = useAuthStore();
    const msalStore = useMsalStore();
    return { localeStore, authStore, msalStore };
  },

  computed: {
    themeName() {
      return this.localeStore.getCurrentLocaleTheme.toLowerCase();
    },
    signInBackgroundImage() {
      return require(`@/common/assets/welcome/sign-in-background/${this.themeName}.svg`);
    },
    featureImageStyle() {
      const hasImgAsset = ["global", "ca", "sg", "au"].includes(this.themeName);
      if (!hasImgAsset) {
        return {
          backgroundColor: "rgba(var(--ion-color-secondary-rgb), 0.2)",
        };
      }
      const img = require(`@/common/assets/welcome/feature-image/${this.themeName}.webp`);
      const backgroundImageStyle = { backgroundImage: `url(${img})` };
      return backgroundImageStyle;
    },
    logo() {
      const extension = ["global", "ca", "sg", "au"].includes(this.themeName)
        ? "svg"
        : "png";
      return require(`@/common/assets/welcome/logo/${this.themeName}.${extension}`);
    },
    logoWrapperClass() {
      return this.themeName === "sg" ? "logo-wrapper-sg" : "logo-wrapper";
    },
    innerWrapperClass() {
      return this.themeName === "sg" ? "inner-wrapper-sg" : "inner-wrapper";
    },
  },
  methods: {
    async signInAZ() {
      this.authStore.storeIsConsultant(false);

      const request = {
        authority: EnvironmentConfig.Azure.Authority,
        extraQueryParameters: {
          ui_locales: this.localeStore?.currentLocale ?? "en",
        },
      };
      await this.msalStore.loginRedirect(request);
    },
    async signInAZMfa() {
      this.authStore.storeIsConsultant(false);

      const request = {
        authority: EnvironmentConfig.Azure.AuthorityMfa,
        extraQueryParameters: {
          ui_locales: this.localeStore?.currentLocale ?? "en",
        },
      };
      await this.msalStore.loginRedirect(request);
    },
  },
  mounted() {
    // Force a logout if they arrived to this page with a MS account
    if (this.msalStore.accountId !== null) {
      this.msalStore.logoutRedirect();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

ion-toolbar {
  --min-height: 70px;
  --background: none;
}
.locale-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: var(--z-index-locale-selector);
  border: 1px solid var(--lighter-grey);
  border-radius: 8px;
  background: var(--background-white);
}

.content {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.feature-image {
  visibility: hidden;

  @include breakpoint(wide) {
    visibility: visible;
    width: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.sign-in-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  right: 0;

  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  background-color: var(--background-white);

  box-shadow: -8px 0px 16px 0px #0000003d;

  @include breakpoint(wide) {
    width: 52.5%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}
ion-content {
  --background: none;
}
.sign-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 44px;
  height: 100%;

  margin: auto;
}

.logo-wrapper {
  width: 260px;
  height: 150px;
}

.logo-wrapper-sg {
  width: 310px;
  height: 150px;
  margin-left: -30px;
}

.sign-in-logo {
  width: 100%;
  height: 100%;
}

.inner-wrapper {
  width: 260px;
  height: 180px;
  flex-grow: 0;
}

.inner-wrapper-sg {
  width: 310px;
  height: 180px;
  flex-grow: 0;
}

.title {
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
  text-align: left;
}

.subtitle {
  color: var(--dark-grey);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
  text-align: left;
}

.sign-in-button {
  width: 100%;
  margin: auto;
}

.built-by {
  padding: 0;
  margin: 8px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: right;

  color: var(--mid-grey);
}
</style>
